/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * This is a GLOBAL layout file. Do not modify it just to
 * satisfy specific use cases. Please consider an
 * alternative approach. If you're unsure of what this means
 * or what to do, speak with a frontend engineer.
 *
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

import React from 'react';
import classnames from 'classnames';

import ErrorBoundary from 'signer-app/parts/error-boundary';
import HsStyleProvider from 'hellosign/common/style/hs-style-provider';
import { useHSUser } from 'hellospa/common/hs-user';
import { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import SkipToContentLink from 'hellospa/components/skip-to-content-link';
import './layout-responsive.module.scss';

import { DefaultLayoutContext } from './context';

const AppTopBar = React.lazy(() => import('hellosign/components/app/header'));
const AppSideBar = React.lazy(() => import('hellosign/components/app/sidebar'));

export default function HelloSignLayoutResponsive(props) {
  const context = React.useContext(DefaultLayoutContext);
  const user = useHSUser();

  const {
    app,
    locale,
    csrfToken,
    signature,
    siteCode,
    // termsURL,
    // privacyURL,
  } = context;

  const signatureModalProps = {
    signatureType: signature.type,
    settings: user.settings,
    csrfToken,
  };

  React.useEffect(
    () => {
      if (app && csrfToken) {
        app.csrfToken = csrfToken;
      } else {
        app.csrfToken = getCSRFToken();
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [],
  ); // Only run on mount.

  const closeSidebar = React.useCallback(() => {
    document.body.classList.remove('responsive-layout__sidebar-open');
  }, []);

  return (
    <HsStyleProvider>
      <div
        className={classnames('responsive-layout', {
          'responsive-layout--full-bleed-content': props.fullBleedContent,
        })}
      >
        <SkipToContentLink />
        <div className="responsive-layout__wrapper">
          <div className="responsive-layout__header">
            <AppTopBar
              withSearch={props.withSearch}
              onSearch={props.onSearch}
              locale={locale}
              responsive
              app={app}
              userId={user.id}
              referUrl={user.referUrl}
              siteCode={siteCode}
              isFree={user.isFree}
              emailAddress={user.emailAddress}
              name={user.name}
              numDocsLeft={user.numDocsLeft}
              numFaxPagesLeft={user.numFaxPagesLeft}
              numApiDocsLeft={user.numApiDocsLeft}
              numTemplatesLeft={user.numTemplatesLeft}
              primarySignatureGuid={user.primarySignatureGuid}
              showApiCredits={user.showApiCredits}
              signatureModalProps={signatureModalProps}
              canAccessAdminConsole={user.canAccessAdminConsole}
              isFromSelfServeApi={user.isFromSelfServeApi}
              isDropboxManaged={user.isDropboxManaged}
              showDelinquencyBanner={user.showDelinquencyBanner}
              nextBillingDate={user.nextBillingDate}
              endDate={user.endDate}
              canManageBilling={user.canManageBilling}
              prevPlanName={user.prevPlanName}
              hasAlternativeBilling={user.hasAlternativeBilling}
              hasFrozenSubscription={user.hasFrozenSubscription}
              freeTrialEndDate={user.freeTrialEndDate}
              showFreeTrialEndBanner={user.showFreeTrialEndBanner}
              ccExpiredBeforeRenewal={user.ccExpiredBeforeRenewal}
              ccExpiringBeforeRenewal={user.ccExpiringBeforeRenewal}
              showExpiredCCNotification={user.showExpiredCCNotification}
            />
            <div className="responsive-layout__sidebar-and-content">
              <div className="responsive-layout__sidebar">
                <AppSideBar
                  app={app}
                  canAccessBulkSendUI={user.canAccessBulkSendUI}
                  canAccessAdminConsole={user.canAccessAdminConsole}
                  canAccessTemplateConverter={user.canAccessTemplateConverter}
                  isNewTemplateConverterEnabled={
                    user.isNewTemplateConverterEnabled
                  }
                  isOldTemplateConverterEnabled={
                    user.isOldTemplateConverterEnabled
                  }
                  canAccessTemplates={!user.isFree}
                  canAccessSignFaxBridge={user.canAccessSignFaxBridge}
                  activeItemId={
                    // At some point I'd like to just have the AppSideBar just use
                    // ReactRouter to figure out which item is active.
                    props.activeItemId
                  }
                  siteCode={siteCode}
                  emailAddress={user.emailAddress}
                  name={user.name}
                  showApi={user.showApi}
                  canShowApiTooltip={user.showApi}
                  userGuid={user.id}
                />
              </div>
              <div className="responsive-layout__content">
                <div
                  className="responsive-layout__screen"
                  onClick={closeSidebar}
                />
                <main
                  id="site-wrapper"
                  className="responsive-layout__main"
                  tabIndex={-1}
                >
                  <ErrorBoundary>{props.children}</ErrorBoundary>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HsStyleProvider>
  );
}
