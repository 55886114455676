import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Template } from 'js/sign-components/generated/types/HelloRequest';
import {
  StoreShape,
  RECENT_TEMPLATES_NAMESPACE_KEY,
} from 'hellospa/redux/namespaces';
import { AsyncThunkConfig } from 'hellospa/redux/types';
import { TemplateTabs } from '.';

const templateAdapter = createEntityAdapter<Template>({
  selectId: (template) => template.guid,
});

const templateSelectors = templateAdapter.getSelectors<StoreShape>(
  (state) => state[RECENT_TEMPLATES_NAMESPACE_KEY].templates,
);

const initialState = {
  tab: null,
  templates: templateAdapter.getInitialState(),
  fetching: false,
  error: false,
};

export type RecentTemplatesNamespaceState = typeof initialState;

const fetchRecentTemplatesThunk = createAsyncThunk<
  { templates: Template[] },
  void,
  AsyncThunkConfig
>(
  `${RECENT_TEMPLATES_NAMESPACE_KEY}/fetchRecentTemplatesThunk`,
  async (_void, thunkAPI) => {
    const { appActions } = thunkAPI.extra();
    return appActions.templates.fetchRecentTemplates();
  },
);

const fetchStarredTemplatesThunk = createAsyncThunk<
  { templates: Template[] },
  void,
  AsyncThunkConfig
>(
  `${RECENT_TEMPLATES_NAMESPACE_KEY}/fetchStarredTemplatesThunk`,
  async (_void, thunkAPI) => {
    const { appActions } = thunkAPI.extra();
    const data = await appActions.templates.fetchTemplates(
      { starred: true, search: null, includeTemplateLinks: false },
      1,
      5,
    );
    return { templates: data.data };
  },
);

export const toggleTemplateStarThunk = createAsyncThunk<
  { templateGuid: string; templateStarred: boolean },
  { template: Template },
  AsyncThunkConfig
>(
  `${RECENT_TEMPLATES_NAMESPACE_KEY}/toggleTemplateStarThunk`,
  async ({ template }, thunkAPI) => {
    const { appActions } = thunkAPI.extra();
    await appActions.templates.setStarred(template.guid, !template.starred);
    return { templateGuid: template.guid, templateStarred: !template.starred };
  },
);

export const setTemplatesTabThunk = createAsyncThunk<
  void,
  { tab: TemplateTabs },
  AsyncThunkConfig
>(
  `${RECENT_TEMPLATES_NAMESPACE_KEY}/setTemplatesTabThunk`,
  async (args, thunkAPI) => {
    if (args.tab === 'recentTemplates') {
      thunkAPI.dispatch(fetchRecentTemplatesThunk());
    } else if (args.tab === 'starredTemplates') {
      thunkAPI.dispatch(fetchStarredTemplatesThunk());
    }
  },
);

const slice = createSlice({
  name: RECENT_TEMPLATES_NAMESPACE_KEY,
  initialState,
  reducers: {
    addTemplates: (state, action: PayloadAction<Template[]>) => {
      templateAdapter.addMany(state.templates, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentTemplatesThunk.pending, (state) => {
        state.fetching = true;
        state.error = false;
        templateAdapter.removeAll(state.templates);
      })
      .addCase(fetchRecentTemplatesThunk.fulfilled, (state, action) => {
        state.fetching = false;
        state.error = false;
        templateAdapter.addMany(state.templates, action.payload.templates);
      })
      .addCase(fetchRecentTemplatesThunk.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(fetchStarredTemplatesThunk.pending, (state) => {
        state.fetching = true;
        state.error = false;
        templateAdapter.removeAll(state.templates);
      })
      .addCase(fetchStarredTemplatesThunk.fulfilled, (state, action) => {
        state.fetching = false;
        state.error = false;
        templateAdapter.addMany(state.templates, action.payload.templates);
      })
      .addCase(fetchStarredTemplatesThunk.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      })
      .addCase(toggleTemplateStarThunk.fulfilled, (state, action) => {
        templateAdapter.updateOne(state.templates, {
          id: action.payload.templateGuid,
          changes: {
            starred: action.payload.templateStarred,
          },
        });
      });
  },
});

export const selectTemplatesData = (
  state: StoreShape,
): {
  status: 'fetching' | 'error' | 'loaded';
  templates: Template[];
} => {
  if (state[RECENT_TEMPLATES_NAMESPACE_KEY].fetching) {
    return {
      status: 'fetching',
      templates: [],
    };
  } else if (state[RECENT_TEMPLATES_NAMESPACE_KEY].error) {
    return {
      status: 'error',
      templates: [],
    };
  } else {
    return {
      status: 'loaded',
      templates: templateSelectors.selectAll(state),
    };
  }
};

export const { addTemplates } = slice.actions;

export default slice.reducer;
