/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isAsyncThunkAction,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Template } from 'js/sign-components/generated/types/HelloRequest';
import { StoreShape, TEMPLATE_NAMESPACE_KEY } from 'hellospa/redux/namespaces';
import { AsyncThunkConfig } from 'hellospa/redux/types';
import { notEmpty } from 'js/sign-components/common/ts-utils';
import invariant from 'invariant';
import { EntityId } from '@reduxjs/toolkit/src/entities/models';
import { createBannerMessage } from 'hellospa/components/notification-banner/data/actions';
import { NotificationBannerType } from 'hellospa/components/notification-banner';
import { defineMessages } from 'react-intl';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { UserSurvey, UserSurveyEvent } from 'common/utils/user_survey';

const messages = defineMessages({
  inPersonLegalNotificationOn: {
    id: '',
    description:
      'Legal notification to show when in person signing is turned on',
    defaultMessage:
      'In-person signing turned on. <strong> Check your email for setup link and instructions.</strong>',
  },
  inPersonLegalNotificationOff: {
    id: '',
    description:
      'Legal notification to show when in person signing is turned off',
    defaultMessage: 'In-person signing turned off.',
  },
});

const templateAdapter = createEntityAdapter<Template>({
  selectId: (template) => template.guid,
});

type BasePagedResult = {
  /**
   * 1-indexed page number
   */
  pageNum: number;
  requestId: string;
  pages: Record<number, Array<Template['guid']>>;
};
type PagedResults<T = {}> =
  | (BasePagedResult &
      T & { status: 'fulfilled'; resultsCount: number; pageSize: number })
  | (BasePagedResult & T & { status: 'rejected'; error?: string })
  | (BasePagedResult & T & { status: 'pending' });

type MyTemplates = PagedResults<{
  starred: boolean;
}>;
type Search = PagedResults<{
  query: string;
}>;

const initialState = {
  templates: templateAdapter.getInitialState(),
  search: undefined as Search | undefined,
  myTemplates: undefined as MyTemplates | undefined,
  includeTemplateLinks: false as boolean,
};
export type TemplateNamespaceState = typeof initialState;

export const fetchTemplatesThunk = createAsyncThunk<
  {
    total: number;
    pageSize: number;
    data: Template[];
  },
  { starred?: boolean; pageNum?: number; includeTemplateLinks: boolean },
  AsyncThunkConfig
>('templates/fetchTemplates', async (arg, thunkAPI) => {
  const { appActions } = thunkAPI.extra();
  const {
    starred = selectCurrentTab(thunkAPI.getState()) === 'starred',
    pageNum = 1,
    includeTemplateLinks = false,
  } = arg;
  selectPageOfTemplates(thunkAPI.getState());

  return appActions.templates.fetchTemplates(
    {
      starred,
      search: null,
      includeTemplateLinks,
    },
    pageNum,
  );
});

export const searchTemplatesThunk = createAsyncThunk<
  {
    total: number;
    pageSize: number;
    data: Template[];
  },
  { query: string; pageNum: number; includeTemplateLinks: boolean },
  AsyncThunkConfig
>(
  'templates/searchTemplates',
  async ({ query, pageNum, includeTemplateLinks }, thunkAPI) => {
    const { appActions } = thunkAPI.extra();
    const starred = selectCurrentTab(thunkAPI.getState()) === 'starred';
    return appActions.templates.fetchTemplates(
      { search: query, starred, includeTemplateLinks },
      pageNum,
    );
  },
);

export const toggleStarThunk = createAsyncThunk<
  {},
  { templateId: Template['guid']; newStarredStatus: boolean },
  AsyncThunkConfig & { pendingMeta: { starred: boolean } }
>(
  'templates/toggleStar',
  async ({ templateId, newStarredStatus }, thunkAPI) => {
    const { getState } = thunkAPI;
    const { appActions } = thunkAPI.extra();

    const template = templateSelectors.selectById(getState(), templateId);
    if (!template) {
      throw new Error(`Failed to find template ${templateId}`);
    }

    await appActions.templates.setStarred(templateId, newStarredStatus);

    return {
      templateId,
      newStarredStatus,
    };
  },
  {
    getPendingMeta(base, thunkAPI) {
      const templateId = base.arg.templateId;
      const template = templateSelectors.selectById(
        thunkAPI.getState(),
        templateId,
      );
      if (!template) {
        throw new Error(`Missing template: ${templateId}`);
      }

      return {
        starred: !template.starred,
      };
    },
  },
);

export const renameTemplateThunk = createAsyncThunk<
  {},
  { templateId: string; newTemplateName: string },
  AsyncThunkConfig & { pendingMeta: { newTemplateName: string } }
>(
  'templates/renameTemplate',
  async ({ templateId, newTemplateName }, thunkAPI) => {
    const { getState } = thunkAPI;
    const { appActions } = thunkAPI.extra();

    const template = templateSelectors.selectById(getState(), templateId);
    if (!template) {
      throw new Error(`Failed to find template ${templateId}`);
    }

    await appActions.templates.renameTemplate(templateId, newTemplateName);

    return {
      templateId,
      newTemplateName,
    };
  },
  {
    getPendingMeta(base, thunkAPI) {
      const templateId = base.arg.templateId;
      const template = templateSelectors.selectById(
        thunkAPI.getState(),
        templateId,
      );
      if (!template) {
        throw new Error(`Missing template: ${templateId}`);
      }

      return {
        newTemplateName: base.arg.newTemplateName,
      };
    },
  },
);

export const toggleRecieveSignedCopiesThunk = createAsyncThunk<
  void,
  { templateGuid: Template['guid']; receiveSignedCopies: boolean },
  AsyncThunkConfig
>('templates/toggleReceiveSignedCopiesThunk', async (arg, thunkAPI) => {
  const { appActions } = thunkAPI.extra();
  await appActions.templates.setReceiveSignedCopies(
    arg.templateGuid,
    arg.receiveSignedCopies,
  );
});

export const toggleInPersonSigningThunk = createAsyncThunk<
  void,
  { templateGuid: Template['guid']; inPersonSigning: boolean },
  AsyncThunkConfig
>('templates/toggleInPersonSigningThunk', async (arg, thunkAPI) => {
  if (arg.inPersonSigning) {
    thunkAPI.dispatch(
      createBannerMessage(
        messages.inPersonLegalNotificationOn,
        NotificationBannerType.Success,
        {
          // eslint-disable-next-line react/display-name
          strong: (messageBody: string) =>
            React.createElement('strong', {}, messageBody),
        },
        {
          key: 'in-person-legal-notification',
          timeout: 0,
        },
      ),
    );
  } else {
    thunkAPI.dispatch(
      createBannerMessage(
        messages.inPersonLegalNotificationOff,
        NotificationBannerType.Success,
        {
          key: 'in-person-legal-notification',
          timeout: 0,
        },
      ),
    );
  }

  const { appActions } = thunkAPI.extra();
  await appActions.templates.setInPersonSigning(
    arg.templateGuid,
    arg.inPersonSigning,
  );
});

export const templatePageThunk = createAsyncThunk<
  void,
  { pageNum: number; includeTemplateLinks: boolean },
  AsyncThunkConfig
>('templates/pageThunk', async (arg, thunkAPI) => {
  const state = ns(thunkAPI.getState());
  if (!state.myTemplates || !state.myTemplates.pages[arg.pageNum]) {
    await thunkAPI.dispatch(
      fetchTemplatesThunk({
        starred: selectCurrentTab(thunkAPI.getState()) === 'starred',
        pageNum: arg.pageNum,
        includeTemplateLinks: arg.includeTemplateLinks,
      }),
    );
  }
});

export enum TemplatePageTab {
  Star = 'starred',
  All = 'all',
}

export enum ActionSource {
  User = 'user',
  System = 'system',
}

export interface SetTabParams {
  tab: TemplatePageTab;
  isFree: boolean;
  source: ActionSource;
}

export const setTab = createAsyncThunk<void, SetTabParams, AsyncThunkConfig>(
  'templates/setTab',
  async ({ tab }, thunkAPI) => {
    const state = ns(thunkAPI.getState());
    await thunkAPI.dispatch(
      fetchTemplatesThunk({
        starred: tab === TemplatePageTab.Star,
        pageNum: 1,
        includeTemplateLinks: state.includeTemplateLinks,
      }),
    );
  },
);

const templateSlice = createSlice({
  name: TEMPLATE_NAMESPACE_KEY,
  initialState,
  reducers: {
    addTemplate: (state, action: PayloadAction<Template>) => {
      templateAdapter.addOne(state.templates, action);
    },
    removeTemplate: (state, key: PayloadAction<EntityId>) => {
      templateAdapter.removeOne(state.templates, key);
    },
    clearTemplateSearch(state) {
      state.search = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTab.fulfilled, (state, action) => {
      const { resultsCount } = state.myTemplates as Extract<
        MyTemplates,
        { status: 'fulfilled' }
      >;
      const { tab: selectedTab, isFree, source } = action.meta.arg;
      if (resultsCount < 1 || isFree) {
        return;
      }
      if (source === ActionSource.System) {
        UserSurvey.trackEvent(UserSurveyEvent.TemplatesPageClick);
      } else if (selectedTab === TemplatePageTab.All) {
        UserSurvey.trackEvent(UserSurveyEvent.TemplatesPageClick);
      } else if (selectedTab === TemplatePageTab.Star) {
        UserSurvey.trackEvent(UserSurveyEvent.StarredTemplatesPageClick);
      }
    });
    builder.addCase(setTab.pending, (state, action) => {
      if (state.myTemplates) {
        state.myTemplates.starred = action.payload === 'starred';
        state.myTemplates.pageNum = 1;
        state.myTemplates.pages = {};
      }
    });
    builder.addCase(templatePageThunk.pending, (state, action) => {
      if (state.myTemplates) {
        state.myTemplates.pageNum = action.meta.arg.pageNum;
      }
    });

    // Optimistic update
    builder.addCase(toggleStarThunk.pending, (state, action) => {
      const templateId = action.meta.arg.templateId;
      const starred = action.meta.arg.newStarredStatus;
      templateAdapter.updateOne(state.templates, {
        id: templateId,
        changes: {
          starred,
        },
      });
    });

    // Roll back if it failed
    builder.addCase(toggleStarThunk.rejected, (state, action) => {
      const templateId = action.meta.arg.templateId;
      const starred = action.meta.arg.newStarredStatus;
      templateAdapter.updateOne(state.templates, {
        id: templateId,
        changes: {
          starred: !starred,
        },
      });
    });

    builder.addCase(renameTemplateThunk.pending, (state, action) => {
      const templateId = action.meta.arg.templateId;
      const newTemplateName = action.meta.arg.newTemplateName;
      templateAdapter.updateOne(state.templates, {
        id: templateId,
        changes: {
          title: newTemplateName,
        },
      });
    });

    builder.addMatcher(
      isAsyncThunkAction(fetchTemplatesThunk),
      (state, action) => {
        if (isRejected(action) && state.myTemplates) {
          state.myTemplates = {
            ...state.myTemplates,
            status: 'rejected',
            error: action.error.message,
          };
        }
        if (fetchTemplatesThunk.pending.match(action)) {
          const { pageNum = 1, includeTemplateLinks } = action.meta.arg;
          const myTemplates: MyTemplates = {
            status: 'pending',
            starred: action.meta.arg.starred || false,
            pageNum,
            pages: {},
            requestId: action.meta.requestId,
          };
          state.myTemplates = myTemplates;
          state.includeTemplateLinks = includeTemplateLinks;
        }
        if (fetchTemplatesThunk.fulfilled.match(action)) {
          if (
            state.myTemplates &&
            state.myTemplates.requestId !== action.meta.requestId
          ) {
            // This request was abandoned. Maybe the user switched tabs
            return;
          }

          templateAdapter.addMany(state.templates, action.payload.data);

          if (state.myTemplates && state.myTemplates.status === 'pending') {
            state.myTemplates.pages[state.myTemplates.pageNum] =
              action.payload.data.map((t) => t.guid);

            const myTemplates: MyTemplates = {
              ...state.myTemplates,
              status: 'fulfilled',
              resultsCount: action.payload.total,
              pageSize: action.payload.pageSize,
            };
            state.myTemplates = myTemplates;
          }
        }
      },
    );

    builder.addMatcher(
      isAsyncThunkAction(searchTemplatesThunk),
      (state, action) => {
        if (searchTemplatesThunk.pending.match(action)) {
          const {
            query: searchTerm,
            pageNum,
            includeTemplateLinks,
          } = action.meta.arg;
          state.includeTemplateLinks = includeTemplateLinks;
          let pages: Search['pages'] = {};
          // Keep the pages if we're on the same search term
          if (state.search && state.search.query === searchTerm) {
            pages = state.search.pages;
          }

          const search: Search = {
            status: 'pending',
            requestId: action.meta.requestId,
            pageNum,
            query: searchTerm,
            pages,
          };
          state.search = search;
        }

        if (searchTemplatesThunk.rejected.match(action) && state.search) {
          state.search = {
            ...state.search,
            status: 'rejected',
            error: action.error.message,
          };
        }
        if (searchTemplatesThunk.fulfilled.match(action)) {
          if (state.search && state.search.status === 'pending') {
            if (state.search.requestId !== action.meta.requestId) {
              // This request was abandoned. Maybe the user switched tabs
              return;
            }
            templateAdapter.addMany(state.templates, action.payload.data);

            const ids = action.payload.data.map((t) => t.guid);
            const search: Search = {
              ...state.search,
              status: 'fulfilled',
              resultsCount: action.payload.total,
              pageSize: action.payload.pageSize,
            };
            state.search = search;
            state.search.pages[search.pageNum] = ids;
          }
        }
      },
    );

    builder.addMatcher(
      isAsyncThunkAction(toggleRecieveSignedCopiesThunk),
      (state, action) => {
        const { templateGuid, receiveSignedCopies } = action.meta.arg;
        const template = state.templates.entities[templateGuid];
        // The invariants help TS to know that template exists, and has
        // linkDetails.
        invariant(template, 'missing template: %s', templateGuid);
        invariant(
          template.linkDetails,
          'missing linkDetails: %s',
          templateGuid,
        );

        if (toggleRecieveSignedCopiesThunk.pending.match(action)) {
          template.linkDetails.receiveSignedCopies = receiveSignedCopies;
        }
      },
    );
    builder.addMatcher(
      isAsyncThunkAction(toggleInPersonSigningThunk),
      (state, action) => {
        const { templateGuid, inPersonSigning } = action.meta.arg;
        const template = state.templates.entities[templateGuid];
        // The invariants help TS to know that template exists, and has
        // linkDetails.
        invariant(template, 'missing template: %s', templateGuid);
        invariant(
          template.linkDetails,
          'missing linkDetails: %s',
          templateGuid,
        );

        const link = `https:${HfReactHelper.urlHelper(`signer/getStartedInPerson?guid=${template.shortGuid}`)}`;

        if (toggleInPersonSigningThunk.pending.match(action)) {
          // Optimistic update
          const inPersonLink = inPersonSigning ? link : undefined;
          template.linkDetails.inPersonLink = inPersonLink;
        } else if (toggleInPersonSigningThunk.rejected.match(action)) {
          // Revert if it rejects
          const inPersonLink = !inPersonSigning ? link : undefined;
          template.linkDetails.inPersonLink = inPersonLink;
        }
      },
    );
  },
});

export const { addTemplate, removeTemplate } = templateSlice.actions;

const ns = (state: StoreShape) => state[TEMPLATE_NAMESPACE_KEY];
const templateSelectors = templateAdapter.getSelectors<StoreShape>(
  (state) => ns(state).templates,
);

const selectTemplatesForCurrentPage = createSelector(
  // Select the ID of all templates in the current page
  (state: StoreShape) => {
    const s = ns(state);
    if (s.myTemplates) {
      return s.myTemplates.pages[s.myTemplates.pageNum] || [];
    }
    return [];
  },
  // Select all templates
  (state: StoreShape) => ns(state).templates,
  // Lookup all the IDs
  (ids, templates) => ids.map((id) => templates.entities[id]).filter(notEmpty),
);

export const selectPageOfTemplates = (state: StoreShape) => {
  const s = ns(state);
  let resultsCount = 0;
  let pageSize = 20;
  let status = 'pending';
  let starred = false;

  const templates = selectTemplatesForCurrentPage(state);
  if (s.myTemplates) {
    status = s.myTemplates.status;
    starred = s.myTemplates.starred;
    if (s.myTemplates.status === 'fulfilled') {
      resultsCount = s.myTemplates.resultsCount;
      pageSize = s.myTemplates.pageSize;
    }
  }

  return {
    criteria: selectTemplateSearchCriteria(state),
    isLoading: status === 'pending',
    templates,
    resultsCount,
    starred,
    currentPage: selectCurrentTemplatePage(state),
    numPages: Math.ceil(resultsCount / pageSize),
  };
};

export const selectTemplateSearchCriteria = (root: StoreShape) => {
  const search = ns(root).search;

  if (search) {
    return {
      pageNum: search.pageNum,
      query: search.query,
    };
  }
  return {};
};

const selectSearchResults = createSelector(
  (state: StoreShape) => {
    const search = ns(state).search;
    if (search && search.status === 'fulfilled') {
      return search.pages[search.pageNum];
    }
    return [];
  },
  (state: StoreShape) => ns(state).templates,
  (ids, templates) => ids.map((id) => templates.entities[id]).filter(notEmpty),
);

export const selectTemplateSearch = createSelector(
  (state: StoreShape) => ns(state).search,
  selectSearchResults,
  (search, results) => {
    if (search) {
      return {
        status: search.status,
        pageNum: search.pageNum,
        resultsCount: search.status === 'fulfilled' ? search.resultsCount : 0,
        query: search.query,
        results,
      };
    }
    return null;
  },
);
export const selectCurrentTemplatePage = (state: StoreShape) => {
  const tmp = ns(state).myTemplates;
  return tmp ? tmp.pageNum : 1;
};
export const selectCurrentTab = (state: StoreShape) => {
  const tmp = ns(state).myTemplates;
  if (tmp && tmp.starred) {
    return 'starred';
  }
  return 'all';
};

export const selectLoadingState = (state: StoreShape) => {
  const tmp = ns(state).myTemplates;
  return tmp ? tmp.status : 'pending';
};

export const selectTemplateResultsCount = (state: StoreShape) => {
  const tmp = ns(state).myTemplates;
  if (tmp && 'resultsCount' in tmp) {
    return tmp.resultsCount;
  }
  return 0;
};

export const selectTemplate = templateSelectors.selectById;

export const { clearTemplateSearch } = templateSlice.actions;
export default templateSlice.reducer;
